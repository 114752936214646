import { Location } from '@michelin/central-provider';
import {
  BillingProfileLocationIVM,
  BillingProfileOVM,
  ContactOVM,
  CustomerOVM,
  signedType,
} from '@michelin/fcp-view-models';
import { isHierarchyHigher } from 'utils/reusableMethods';
import { ProfileParams, ServiceParams } from './Profiles/Components/SpeedDialActions';
import {
  AuthorizationService,
  BillingProfileTypes,
  BillingServiceTypes,
  ContactsTypesAuth,
  checksPO,
} from './Profiles/types';

export function transformAsigneeToBillingLocation(locations: Location[]): CustomerOVM[] {
  const assignmentsToBillingProfileLocations = locations.map((x) => ({
    assigned: true,
    hash_key: x.hash_key,
    customer_number: x.customer_number,
    relationship: x.relationship || '',
    extrnl_cust_id: x.extrnl_cust_id || '',
    customer_name: x.customer_name,
    customer_addr1: x.customer_addr1,
    customer_addr2: x.customer_addr2 || '',
    customer_city: x.customer_city,
    customer_state: x.customer_state,
    customer_zip: x.customer_zip,
    customer_type: x.customer_type,
    cas: x.customer_addr1,
  }));
  return assignmentsToBillingProfileLocations;
}

export const isInContacsWithLimitsEdited = (input: string | undefined, contactList: ContactOVM[]) => {
  if (!input) {
    return false;
  }
  let isSpecial = false;
  for (let i = 0; i < contactList.length; i++) {
    if (contactList[i].id === input) {
      isSpecial = true;
      break;
    }
  }
  return isSpecial;
};

export const preApprovalContactsFiltering = ({
  ownerContacts,
  profile,
  assignedLocations,
  location,
  loggedLocations,
}: {
  ownerContacts: ContactOVM[];
  profile: BillingProfileOVM;
  assignedLocations: CustomerOVM[];
  location?: Location;
  loggedLocations: CustomerOVM[];
}): ContactOVM[] => {
  let auxReturn: ContactOVM[] = [];
  if (!location || !profile) return [];
  if (profile.general_information?.set_all_locations) {
    if (profile.owner?.customer_number === location.customer_number) {
      auxReturn = ownerContacts;
    } else {
      const higher = isHierarchyHigher(location.customer_type, profile.owner?.customer_type || '');
      if (higher) {
        auxReturn = ownerContacts;
      } else {
        auxReturn = ownerContacts.filter((contact) =>
          contact.locations?.some((contactLocation) =>
            loggedLocations.some(
              (loggedLocation) =>
                loggedLocation.relationship?.includes(contactLocation.hash_key?.split('~')[1] ?? '') ?? false,
            ),
          ),
        );
      }
    }
  } else {
    auxReturn = ownerContacts.filter((contact) =>
      contact.locations?.some((contactLocation) =>
        assignedLocations.some(
          (assignedLocation) =>
            assignedLocation.relationship?.includes(contactLocation.hash_key?.split('~')[1] ?? '') ?? false,
        ),
      ),
    );
  }
  return auxReturn;
};

export const mapProfileToSave = (profile: BillingProfileOVM) => {
  return {
    hash_key: profile.hash_key || undefined,
    owner_key: profile.owner_key,
    owner_relationship: profile.owner_relationship,
    owner_level: profile.owner_level || profile.owner?.customer_type || '',
    assigned_locations: profile.general_information?.set_all_locations ? [] : profile.assigned_locations,
    general_information: profile.general_information,
    ers_purchasing_procedures: ersPurchasingProceduresToSave(
      profile.ers_purchasing_procedures,
      profile.general_information?.profile_type || '',
    ),
    onsite_purchasing_procedures: onSitePurchasingProceduresToSave(
      profile.onsite_purchasing_procedures,
      profile.general_information?.profile_type || '',
    ),
    approvals: appprovalsToSave(
      profile.approvals,
      profile.general_information?.service_type || '',
      profile.general_information?.profile_type || '',
    ),
    tire_details:
      profile.general_information?.service_type === BillingServiceTypes.TIRE_WHEEL ? profile.tire_details : [],
    wheel_details:
      profile.general_information?.service_type === BillingServiceTypes.TIRE_WHEEL ? profile.wheel_details : [],
    requested_photos: profile.requested_photos,
  };
};

function ersPurchasingProceduresToSave(
  ers_purchasing_procedures: BillingProfileOVM['ers_purchasing_procedures'],
  profileType: string,
) {
  if (profileType === BillingProfileTypes.onsite) {
    return undefined;
  }

  return {
    required_authorization_to_begin_service: ers_purchasing_procedures?.required_authorization_to_begin_service || '',
    primary_method_to_request_service: ers_purchasing_procedures?.primary_method_to_request_service || '',
    secondary_method_to_request_service: ers_purchasing_procedures?.secondary_method_to_request_service || '',
    method_to_receive_signed_order:
      ers_purchasing_procedures?.method_to_receive_signed_order === 'Select'
        ? ('' as signedType)
        : ers_purchasing_procedures?.method_to_receive_signed_order || ('' as signedType),
    pri_phone_number: ers_purchasing_procedures?.pri_phone_number,
    sec_phone_number: ers_purchasing_procedures?.sec_phone_number,
    signed_order_receiver_other: ers_purchasing_procedures?.signed_order_receiver_other,
    pri_ers_authorized_contact_type: ers_purchasing_procedures?.pri_ers_authorized_contact_type || [],
    sec_ers_authorized_contact_type: ers_purchasing_procedures?.sec_ers_authorized_contact_type || [],
    pri_ers_authorized_contact_level: ers_purchasing_procedures?.pri_ers_authorized_contact_level || '',
    sec_ers_authorized_contact_level: ers_purchasing_procedures?.sec_ers_authorized_contact_level || '',
    ers_ship_to_hash_key: emptyStringToNull(ers_purchasing_procedures?.ers_ship_to_hash_key),
    signed_order_receiver_location: emptyStringToNull(ers_purchasing_procedures?.signed_order_receiver_location),
    signed_order_receiver_contact: emptyStringToNull(ers_purchasing_procedures?.signed_order_receiver_contact),
    required_for_ers_event: ers_purchasing_procedures?.required_for_ers_event || false,
  };
}

function onSitePurchasingProceduresToSave(
  onsite_purchasing_procedures: BillingProfileOVM['onsite_purchasing_procedures'],
  profileType: string,
) {
  if (profileType === BillingProfileTypes.ers) {
    return undefined;
  }

  return {
    required_authorization_to_begin_service:
      onsite_purchasing_procedures?.required_authorization_to_begin_service || '',
    primary_method_to_request_service: onsite_purchasing_procedures?.primary_method_to_request_service || '',
    secondary_method_to_request_service: onsite_purchasing_procedures?.secondary_method_to_request_service || '',
    method_to_receive_signed_order:
      onsite_purchasing_procedures?.method_to_receive_signed_order === 'Select'
        ? ('' as signedType)
        : onsite_purchasing_procedures?.method_to_receive_signed_order || ('' as signedType),
    pri_phone_number: onsite_purchasing_procedures?.pri_phone_number,
    sec_phone_number: onsite_purchasing_procedures?.sec_phone_number,
    signed_order_receiver_other: onsite_purchasing_procedures?.signed_order_receiver_other,
    pri_ers_authorized_contact_type: onsite_purchasing_procedures?.pri_ers_authorized_contact_type || [],
    sec_ers_authorized_contact_type: onsite_purchasing_procedures?.sec_ers_authorized_contact_type || [],
    pri_ers_authorized_contact_level: onsite_purchasing_procedures?.pri_ers_authorized_contact_level || '',
    sec_ers_authorized_contact_level: onsite_purchasing_procedures?.sec_ers_authorized_contact_level || '',
    ers_ship_to_hash_key: onsite_purchasing_procedures?.ers_ship_to_hash_key || null,
    signed_order_receiver_location: emptyStringToNull(onsite_purchasing_procedures?.signed_order_receiver_location),
    signed_order_receiver_contact: emptyStringToNull(onsite_purchasing_procedures?.signed_order_receiver_contact),
  };
}
function appprovalsToSave(approvals: BillingProfileOVM['approvals'], serviceType: string, profileType: string) {
  if (serviceType === BillingServiceTypes.MECHANICAL) {
    return {
      ers_pre_approvals_limits: {
        maximum_num_of_tires: null,
        maxium_num_of_wheels: null,
        maximum_dollar_amount: null,
      },
      onsite_pre_approvals_limits: {
        maximum_num_of_tires: null,
        maxium_num_of_wheels: null,
        maximum_dollar_amount: approvals?.onsite_pre_approvals_limits?.maximum_dollar_amount,
      },
    };
  }
  if (profileType === BillingProfileTypes.onsite) {
    return {
      ers_pre_approvals_limits: {
        maximum_num_of_tires: null,
        maxium_num_of_wheels: null,
        maximum_dollar_amount: null,
      },
      onsite_pre_approvals_limits: {
        maximum_num_of_tires: approvals?.onsite_pre_approvals_limits?.maximum_num_of_tires,
        maxium_num_of_wheels: approvals?.onsite_pre_approvals_limits?.maxium_num_of_wheels,
        maximum_dollar_amount: approvals?.onsite_pre_approvals_limits?.maximum_dollar_amount,
      },
    };
  }
  if (profileType === BillingProfileTypes.ers) {
    return {
      ers_pre_approvals_limits: {
        maximum_num_of_tires: approvals?.ers_pre_approvals_limits?.maximum_num_of_tires,
        maxium_num_of_wheels: approvals?.ers_pre_approvals_limits?.maxium_num_of_wheels,
        maximum_dollar_amount: null,
      },
      onsite_pre_approvals_limits: {
        maximum_num_of_tires: null,
        maxium_num_of_wheels: null,
        maximum_dollar_amount: null,
      },
    };
  }
  return approvals;
}

function emptyStringToNull(value: string | null | undefined): string | null {
  return value === '' || value == null ? null : value;
}

export const mergeNewAssignedLocations = (
  oldLocations: BillingProfileLocationIVM[],
  newLocations: BillingProfileLocationIVM[],
) => {
  const existingKeys = new Set(newLocations.map((location) => location.hash_key));
  const mergedLocations = [...newLocations];
  oldLocations.forEach((newLocation) => {
    if (!existingKeys.has(newLocation.hash_key)) {
      mergedLocations.push({ ...newLocation, assigned: false });
    }
  });
  return mergedLocations;
};

export const newBillingProfile = ({
  location,
  checks,
  isShipTo,
  service,
  profile,
}: {
  location: Location | undefined;
  checks: checksPO;
  isShipTo: boolean;
  service: ServiceParams | '';
  profile: ProfileParams | '';
}): BillingProfileOVM => {
  return {
    owner_key: location?.hash_key || '',
    owner_relationship: location?.relationship || '',
    owner_level: location?.customer_type || '',
    owner: {
      customer_type: location?.customer_type || '',
      customer_number: location?.customer_number || '',
      hash_key: location?.hash_key || '',
      relationship: location?.relationship || '',
    },
    assigned_locations: [],
    general_information: {
      default: false,
      service_type:
        service === ServiceParams.MECHANICAL ? BillingServiceTypes.MECHANICAL : BillingServiceTypes.TIRE_WHEEL,
      profile_type:
        profile === ProfileParams.ERS
          ? BillingProfileTypes.ers
          : profile === ProfileParams.ERS_ONSITE
          ? BillingProfileTypes.ers_onsite
          : BillingProfileTypes.onsite,
      profile_name: '',
      participates_in_approve_orders: checks.participates_in_approve_orders,
      po_required_for_service: checks.po_required_for_service,
      driver_name_required: false,
      driver_signature_required: false,
      set_all_locations: isShipTo ? true : false,
    },
    ers_purchasing_procedures: {
      ers_ship_to_hash_key: null,
      required_for_ers_event: false,
      required_authorization_to_begin_service: checks.po_required_for_service ? 'po' : '',
      primary_method_to_request_service: checks.po_required_for_service ? AuthorizationService.fleet_contact : '',
      pri_phone_number: '',
      pri_ers_authorized_contact_type: checks.po_required_for_service ? [ContactsTypesAuth.PO_ISSUER] : [],
      pri_ers_authorized_contact_level: '',
      secondary_method_to_request_service: '',
      sec_phone_number: '',
      sec_ers_authorized_contact_type: [],
      sec_ers_authorized_contact_level: '',
      method_to_receive_signed_order: 'Select',
      signed_order_receiver_contact: null,
      signed_order_receiver_location: null,
      signed_order_receiver_other: '',
    },
    onsite_purchasing_procedures: {
      ers_ship_to_hash_key: null,
      required_for_ers_event: false,
      required_authorization_to_begin_service: checks.po_required_for_service ? 'po' : '',
      primary_method_to_request_service: checks.po_required_for_service ? AuthorizationService.fleet_contact : '',
      pri_phone_number: '',
      pri_ers_authorized_contact_type: checks.po_required_for_service ? [ContactsTypesAuth.PO_ISSUER] : [],
      pri_ers_authorized_contact_level: '',
      secondary_method_to_request_service: '',
      sec_phone_number: '',
      sec_ers_authorized_contact_type: [],
      sec_ers_authorized_contact_level: '',
      method_to_receive_signed_order: 'Select',
      signed_order_receiver_contact: null,
      signed_order_receiver_location: null,
      signed_order_receiver_other: '',
    },
    approvals: {
      ers_pre_approvals_limits: {
        maximum_dollar_amount: 0,
        maximum_num_of_tires: 0,
        maxium_num_of_wheels: 0,
      },
      onsite_pre_approvals_limits: {
        maximum_dollar_amount: 0,
        maximum_num_of_tires: 0,
        maxium_num_of_wheels: 0,
      },
    },
    tire_details: [],
    wheel_details: [],
    requested_photos: {
      types: [],
      identifier_type: '',
      other_photos: '',
    },
  };
};
